import { FC } from "react";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import { Empty, Row, Typography, Button, PlusCircleOutlined, EmptyInsight } from "@ctra/components";
import { Enterprise as App, EnterpriseAppState, FarmEntity, InsightWorkflowState } from "@ctra/api";
import { Nullable } from "@ctra/utils";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { GAEvent } from "@ctra/analytics";

import { InsightSettingsProvider } from "@settings";
import { CompiledRoutes } from "@routes";
import { testIds, GAActions, GACategories } from "@insights";

import styles from "./EmptyList.module.less";

const { Paragraph } = Typography;

/**
 * Empty insight list with call to action if the insights are not enabled
 * @param {number | undefined} farmID
 * @param {InsightWorkflowState | undefined} workflowState
 * @returns {JSX.Element}
 * @constructor
 */
export const EmptyList: FC<{ farmID?: FarmEntity["id"]; workflowState?: InsightWorkflowState }> = ({
  farmID,
  workflowState
}) => {
  const { t } = useTranslation();

  const {
    insights: {
      list: { empty }
    }
  } = Enterprise;

  /**
   * Get farm (if applicable)
   */
  const farm = useSelector<EnterpriseAppState, Nullable<FarmEntity>>((state) =>
    farmID ? App.entities.getFarm(state, { farmID }) : null
  );

  const toCheckState = workflowState === InsightWorkflowState.toCheck;

  return (
    <InsightSettingsProvider>
      {(insightSettings, { isLoading }) => {
        const hasSettings = !!(farmID
          ? _.filter(insightSettings[farmID], "isEnabled").length
          : _.filter(insightSettings, "isEnabled").length);

        const farmName = farm ? farm.name : null;
        const status = hasSettings ? "enabled" : "disabled";
        const settingsPageRoute = CompiledRoutes.app.settings.insights;

        return (
          <Row
            justify="center"
            align="middle"
            data-testid={
              testIds.list.emptyState.enableInsights[hasSettings ? "hasSettings" : "hasNoSettings"]
            }
            className={styles.Wrapper}
          >
            <Empty
              className={!toCheckState ? styles.HideIcon : void 0}
              image={<EmptyInsight />}
              description={null}
            >
              <Paragraph strong>{t<string>(empty.title, { farmName, status, workflowState })}</Paragraph>
              <Paragraph>{t<string>(empty.description, { farmName, status, workflowState })}</Paragraph>
              {toCheckState && (
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    GAEvent(GACategories.insights, GAActions.goToEnableInsight);
                    App.history.push(
                      farmID ? settingsPageRoute.farm.index({ farmID }) : settingsPageRoute.index()
                    );
                  }}
                  icon={<PlusCircleOutlined />}
                  type="primary"
                >
                  {t<string>(empty.action, { farmName, status })}
                </Button>
              )}
            </Empty>
          </Row>
        );
      }}
    </InsightSettingsProvider>
  );
};
