import { ComponentProps, FC } from "react";
import * as _ from "lodash";

import { Badge, Menu } from "@ctra/components";
import { InsightWorkflowState } from "@ctra/api";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { GAEvent } from "@ctra/analytics";

import { GAActions, GACategories, useInsightlist } from "@insights";

import styles from "./TabNavigation.module.less";

/**
 * Insight tabs: To Check, To Follow up, Done
 */
export const TabNavigation: FC<{ showDoneState?: boolean }> = ({ showDoneState = true }) => {
  const { t } = useTranslation();

  const {
    setInsightStateFilter,
    insightStateFilter,
    meta: { toFollowUpCount, unseenCount }
  } = useInsightlist();

  const {
    insights: {
      workflowState: { toCheck, toFollowUp, done }
    }
  } = Enterprise;

  /**
   * Handle click on the menu
   * @param {string} key
   */
  const onClick: ComponentProps<typeof Menu>["onClick"] = ({ key }) => {
    setInsightStateFilter(key as InsightWorkflowState);
    GAEvent(GACategories.insights, GAActions.selectPage, _.startCase(key));
  };

  /**
   * Menu items
   * @type {(MenuItemType | SubMenuType | MenuItemGroupType | MenuDividerType | null)[]}
   */
  const items: ComponentProps<typeof Menu>["items"] = _.compact([
    {
      label: (
        <Badge offset={[10, 0]} count={unseenCount} overflowCount={9} size="small" dot={unseenCount === 1}>
          {t<string>(toCheck)}
        </Badge>
      ),
      key: InsightWorkflowState.toCheck
    },
    {
      label: (
        <Badge
          offset={[10, 0]}
          count={toFollowUpCount}
          className={styles.ToFollowUpBadge}
          size="small"
          dot={toFollowUpCount === 1}
        >
          {t<string>(toFollowUp)}
        </Badge>
      ),
      key: InsightWorkflowState.toFollowUp
    },
    showDoneState
      ? {
          label: t<string>(done),
          key: InsightWorkflowState.done
        }
      : null
  ]);

  return (
    <Menu
      selectedKeys={[insightStateFilter]}
      onClick={onClick}
      mode="horizontal"
      className={styles.TabNavigation}
      items={items}
    />
  );
};
