import { FC, ComponentProps, useState, useEffect } from "react";
import { useLocation } from "react-router";
import * as _ from "lodash";
import { withProps } from "hoc-with-props";

import {
  Enterprise as Content,
  useTranslation,
  supportedLocales,
  SupportedLocaleKeys,
  SupportedLocales
} from "@ctra/i18n";

import {
  Button,
  Flag,
  DownOutlined,
  Dropdown,
  LoadingOutlined,
  Menu,
  RightOutlined,
  Spin,
  Icon,
  Typography,
  Space
} from "@ctra/components";

import { endToEndTest, UnitSystem } from "@ctra/utils";
import { makeTrackEvent } from "@ctra/analytics";

import { Routes } from "@routes";
import { useLocalization } from "@base";

import { GACategories, GAActions } from "../../analytics";
import styles from "./UserPreferences.module.less";

const { Text } = Typography;

/**
 * User Preferences selector of 3 types
 * Login Page, Dashboard, User Menu
 * Uppercase so it can refer to the CSS Class e.g styles.Auth
 */
export enum UserPreferencesVariant {
  auth = "Auth",
  dashboard = "Dashboard",
  settings = "Settings"
}

interface UserPreferencesProps {
  variant?: UserPreferencesVariant;
  locales?: SupportedLocales;
}

/**
 * User preference selector
 * @constructor
 */
export const UserPreferences: FC<UserPreferencesProps> = ({
  variant = UserPreferencesVariant.dashboard,
  locales = supportedLocales
}) => {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { pathname } = useLocation();

  const {
    unitSystem,
    locale,
    updateUnitSystem,
    updateLocale,
    meta: { isLoading: userPreferencesLoading }
  } = useLocalization();

  const trackEvent = makeTrackEvent(GACategories.userPreferencesSelector);

  /**
   * i18n to be in sync with locale from user prefs
   */
  useEffect(() => {
    if (locale && i18n.language !== locale) {
      setLoading(true);
      i18n.changeLanguage(locale);
    }
  }, [locale, i18n]);

  /**
   * register on mount an event listener for when language is changed
   * this help us visualize the loading state
   * unregister on unmount
   */
  useEffect(() => {
    i18n.on("languageChanged", () => setLoading(false));
    return () => {
      i18n.off("languageChanged");
    };
  }, [i18n]);

  /**
   * Locale keys that are to be mapped
   */
  const localeKeys = _.keys(locales) as Array<SupportedLocaleKeys>;

  const isSettingsVariant = variant === UserPreferencesVariant.settings;
  const isAuthVariant = variant === UserPreferencesVariant.auth;

  /**
   * Language change handler
   */
  const onLanguageChange = ({ key }: { key: SupportedLocaleKeys }) => {
    setLoading(true);
    updateLocale(key);
    i18n.changeLanguage(key);
  };

  /**
   * Unit system change handler
   * update unit system in the localization context
   */
  const onUnitChange = ({ unitSystem }: { unitSystem: UnitSystem }) => {
    updateUnitSystem(unitSystem);
  };

  /**
   * get the title with the given country code
   */
  const getTitle = (locale: SupportedLocaleKeys) => locales[locale].title;

  /**
   * Map of units to show on dropdown button
   */
  const units = {
    [UnitSystem.imperial]: t<string>(Content.navigation.unitSystem.imperial, { variant: "short" }),
    [UnitSystem.metric]: t<string>(Content.navigation.unitSystem.metric, { variant: "short" })
  };

  /**
   * Dropdown button text
   * to be shown to logged in user
   */
  const buttonText = `${getTitle(locale)}, ${units[unitSystem]}`;

  /**
   * Dropdown toggle button
   */
  const button = (
    <Button
      className={styles[variant]}
      // @ts-ignore - the flag component will be fine
      icon={<Icon component={withProps({ countryCode: locales[locale].countryCode })(Flag)} />}
      data-testid={endToEndTest("settings", "dropdownButton")}
      data-gtm-category={GACategories.userPreferencesSelector}
      data-gtm-action="Open locale settings"
      onClick={() => trackEvent(GAActions.openUserPreferencesSelector)}
    >
      <Space size="small">
        <Text>{isAuthVariant ? getTitle(locale) : buttonText}</Text>
        {loading || userPreferencesLoading ? (
          <LoadingOutlined />
        ) : isSettingsVariant ? (
          <RightOutlined />
        ) : (
          <DownOutlined />
        )}
      </Space>
    </Button>
  );

  /**
   * Menu overlay for dropdown
   */
  const overlay = (
    <>
      <Menu
        data-testid={endToEndTest("settings", "dropdownMenu")}
        onClick={(e) => {
          /* istanbul ignore next */
          trackEvent(GAActions.selectLanguage, { label: e.key });
          onLanguageChange({ key: e.key as SupportedLocaleKeys }) as ComponentProps<typeof Menu>["onClick"];
        }}
        selectedKeys={[locale]}
      >
        <Menu.ItemGroup
          title={t<string>(Content.navigation.language.label)}
          className={styles[`${variant}Group`] || styles.Group}
        >
          {localeKeys.map((value) => (
            <Menu.Item
              data-testid={endToEndTest("settings", `language-${value}`)}
              key={value}
              // @ts-ignore - the flag component will be fine
              icon={<Icon component={withProps({ countryCode: locales[value].countryCode })(Flag)} />}
            >
              <Text>{getTitle(value)}</Text>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
      {!isAuthVariant && (
        <Menu
          onClick={(e) => {
            /* istanbul ignore next */
            trackEvent(GAActions.selectUnitSystem, { label: e.key });
            onUnitChange({ unitSystem: e.key as UnitSystem }) as ComponentProps<typeof Menu>["onClick"];
          }}
          selectedKeys={[unitSystem]}
        >
          <Menu.ItemGroup
            title={t<string>(Content.navigation.unitSystem.label)}
            className={styles[`${variant}Group`] || styles.Group}
          >
            <Menu.Item key={UnitSystem.imperial} data-testid={endToEndTest("settings", "imperialUnit")}>
              {t<string>(Content.navigation.unitSystem.imperial, { variant: "extended" })}
            </Menu.Item>
            <Menu.Item key={UnitSystem.metric} data-testid={endToEndTest("settings", "metricUnit")}>
              {t<string>(Content.navigation.unitSystem.metric, { variant: "extended" })}
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      )}
    </>
  );

  /**
   * if settings variant, show only button on /settings path and not an actual dropdown
   * button used to route to other page i.e. /settings/preferences, which shows the overlay
   */
  const renderSettingsVariant = () =>
    pathname === Routes.app.settings.preferences ? (
      <Spin spinning={loading || userPreferencesLoading}>{overlay}</Spin>
    ) : (
      button
    );

  return localeKeys.length > 1 ? (
    isSettingsVariant ? (
      renderSettingsVariant()
    ) : (
      <Dropdown trigger={["click"]} dropdownRender={() => overlay} placement={"bottomLeft"}>
        {button}
      </Dropdown>
    )
  ) : null;
};
